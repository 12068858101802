import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/pgegenfurtner/Projekte/interness/atacama/atacama-packages/packages/web-core/src/templates/mdx/default.js";
import { FaHands } from 'react-icons/fa';
import Heading from '@interness/web-core/src/components/text/Heading/Heading';
import CallToAction from '@interness/theme-sonora/src/components/CallToAction/CallToAction';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Wrapper = makeShortcode("Wrapper");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <hr></hr>
  <Heading icon={<FaHands mdxType="FaHands" />} subtitle="Verantwortung für unsere Umwelt und Gesellschaft" mdxType="Heading">Nachhaltigkeit bei
  Trauringstudio Spieker</Heading>
    <Wrapper width={1024} mdxType="Wrapper">
      <p>{`  Als ortsansässiger Juwelier und Familienunternehmen legen wir großen Wert auf gelebte Nachhaltigkeit.`}</p>
      <p>{`  Wir setzen uns aktiv dafür ein, dass unsere Branche einen positiven Einfluss auf die Umwelt und die Gesellschaft hat.`}</p>
      <p>{`  Wir sind uns bewusst, dass die Gewinnung von Edelmetallen und Edelsteinen mit ökologischen und sozialen Heraus-
forderungen verbunden ist, und wir möchten unseren Teil dazu beitragen, diese Herausforderungen anzugehen.`}</p>
      <p>{`  Wir beziehen unseren Schmuck und Uhren von vertrauenswürdigen Lieferanten und langjährigen Geschäftspartnern,
die strenge Standards in Bezug auf Arbeitsbedingungen, Menschenrechte und den Schutz der Umwelt einhalten. So
unterstützen wir die Förderung von fair gehandelten Edelmetallen, Edelsteinen und Perlen.`}</p>
      <p>{`  Der Schutz unserer Umwelt liegt uns sehr am Herzen. Daher entsorgen wir Batterien fachgerecht und achten bewusst
darauf, dass unsere Verpackungen nachhaltig und umweltfreundlich gestaltet sind, ohne dabei auf Luxus, Qualität und
optisch Anmutung zu verzichten.`}</p>
      <ul>
        <li parentName="ul">{`Trauringe hergestellt in Deutschland`}</li>
        <li parentName="ul">{`Trauringe aus recyceltem Gold`}</li>
        <li parentName="ul">{`Schmuck hergestellt in Deutschland`}</li>
        <li parentName="ul">{`Verpackungen aus recyceltem Material`}</li>
        <li parentName="ul">{`Tüten aus einem sozialen Projekt`}</li>
        <li parentName="ul">{`Recycling von Batterien`}</li>
      </ul>
      <p>{`  Ihr nachhaltig orientierter Juwelier Spieker in Hövelhof`}</p>
      <hr></hr>
  <CallToAction description="Vereinbaren Sie noch heute Ihren Termin zur Trauringberatung" button="Zur Terminvereinbarung" link="/terminvereinbarung" mdxType="CallToAction" />
      <hr></hr>
    </Wrapper>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      